@media (max-device-width: 599px) {
    footer > div > div > div {
        flex-grow: 0 !important;
        max-width: 50% !important;
        flex-basis: 50% !important;
    }

    footer span {
        font-size: 2vw !important;
    }

    footer ul {
        font-size: 2vw !important;
    }

    footer .MuiSvgIcon-root {
        width: 2em !important;
        height: 2em !important;
    }

    footer h2 {
        font-size: 3vw !important;
    }
       
}