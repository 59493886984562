#titleHowitworks > span {
    width: 70%;
}

@media (max-device-width: 600px) {
    #home .MuiTypography-h5 {
        font-size: 5vw !important;
    }

    #home #btnHIW {
        font-size: 3vw !important;
    }

    #home .MuiTypography-h4 {
        font-size: 5vw !important;
    }

    #home p {
        font-size: 3vw !important;
    }

    #home .MuiTypography-h6 {
        font-size: 3vw !important;
    }
}